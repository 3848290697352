@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    @apply text-primary bg-indigo-100;
  }
}

@layer components {

}